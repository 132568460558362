import { graphql } from 'gatsby'
import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import SearchBox from '../components/search-box'

const Search = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <>
      <Layout location={location} title={siteTitle}>
        <SEO title='Search' />
        <div className='page-search'>
          <h1>Search</h1>
          <SearchBox />
          <b>
            <small>
              NOTE:
            </small>
          </b>
          <p>
            <small>
              検索キーワードは英語のみ対応．<br />
              日本語には非対応．<br />
            </small>
          </p>
        </div>
      </Layout>
    </>
  )
}
export default Search

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
